import React from 'react';

import CoursesList from './CoursesList';


export default function DisabledDemo() {
    return (
        <section className="section">
            <CoursesList />
        </section>
    )
}
