import React from 'react';
import PaperCard from './PaperCard';

const papers = [
    { "Authors": "Raouhi E. M., Lachgar M., Hrimech H., Kartit A.", "Title": "Unmanned Aerial Vehicle-based Applications in Smart Farming: A Systematic Review", "Year": "2023", "Source title": "International Journal of Advanced Computer Science and Applications", "DOI": "10.14569/IJACSA.2023.01406123   ", "Link": "https://thesai.org/Publications/ViewPaper?Volume=14&Issue=6&Code=IJACSA&SerialNo=123" },
    { "Authors": "Aatila M., Lachgar M., Hrimech H., Kartit A.", "Title": "Machine Learning Techniques in Keratoconus Classification: A Systematic Review", "Year": "2023", "Source title": "International Journal of Advanced Computer Science and Applications", "DOI": "10.14569/IJACSA.2023.0140569", "Link": "https://thesai.org/Publications/ViewPaper?Volume=14&Issue=5&Code=IJACSA&SerialNo=69" },
    { "Authors": "Aboulmira A., Hrimech H., Lachgar M.", "Title": "Evaluating FFT-Based Convolutions on Skin Diseases Dataset", "Year": "2023", "Source title": "Lecture Notes on Data Engineering and Communications Technologies", "DOI": "10.1007/978-3-031-27762-7_39", "Link": "https://www.scopus.com/inward/record.uri?eid=2-s2.0-85151300172&doi=10.1007%2f978-3-031-27762-7_39&partnerID=40&md5=f902822f9ced11fab181056f3715fa2c" },
    { "Authors": "Lachgar M., Hanine M., Benouda H., Ommane Y.", "Title": "Decision Framework for Cross-Platform Mobile Development Frameworks Using an Integrated Multi-Criteria Decision-Making Methodology", "Year": "2022", "Source title": "International Journal of Mobile Computing and Multimedia Communications", "DOI": "10.2174/2210327912666220610123330", "Link": "https://www.scopus.com/inward/record.uri?eid=2-s2.0-85139466371&doi=10.2174%2f2210327912666220610123330&partnerID=40&md5=b920521088273626678a08870eea7905" },
    { "Authors": "Assad N., Moujahdi C., Hannani A.E., Hanine M., Lachgar M.", "Title": "An Analytical Model of Multi-intrusion Detection for Heterogeneous Wireless Sensor Networks Applied in the Monitoring Area", "Year": "2022", "Source title": "International Journal of Sensors, Wireless Communications and Control", "DOI": "10.2174/2210327912666220610123330", "Link": "https://www.scopus.com/inward/record.uri?eid=2-s2.0-85139466371&doi=10.2174%2f2210327912666220610123330&partnerID=40&md5=b920521088273626678a08870eea7905" },
    { "Authors": "Bourkoukou O., El Bachari E., Lachgar M.", "Title": "Recommendation method based on learner profile and demonstrated knowledge", "Year": "2022", "Source title": "Indonesian Journal of Electrical Engineering and Computer Science", "DOI": "10.11591/ijeecs.v26.i3.pp1634-1642", "Link": "https://www.scopus.com/inward/record.uri?eid=2-s2.0-85131636423&doi=10.11591%2fijeecs.v26.i3.pp1634-1642&partnerID=40&md5=0244380174e9e3d5a1c3152be1f6d94a" },
    { "Authors": "Aatila M., Lachgar M., Hrimech H., Kartit A.", "Title": "Transfer Learning in Keratoconus Classification", "Year": "2022", "Source title": "International journal of online and biomedical engineering", "DOI": "10.3991/ijoe.v18i15.33689", "Link": "https://www.scopus.com/inward/record.uri?eid=2-s2.0-85144687811&doi=10.3991%2fijoe.v18i15.33689&partnerID=40&md5=852e51a9155953f2f180be73749c3f28" },
    { "Authors": "Idrissi Z.K., Lachgar M., Hrimech H.", "Title": "Blockchain, IoT and AI revolution within transport and logistics", "Year": "2022", "Source title": "2022 IEEE 14th International Conference of Logistics and Supply Chain Management, LOGISTIQUA 2022", "DOI": "10.1109/LOGISTIQUA55056.2022.9938035", "Link": "https://www.scopus.com/inward/record.uri?eid=2-s2.0-85143078442&doi=10.1109%2fLOGISTIQUA55056.2022.9938035&partnerID=40&md5=cef19e37c7066a7a8f26eda2f677be3b" },
    { "Authors": "Aboulmira A., Hrimech H., Lachgar M.", "Title": "Comparative Study of Multiple CNN Models for Classification of 23 Skin Diseases", "Year": "2022", "Source title": "International journal of online and biomedical engineering", "DOI": "10.3991/ijoe.v18i11.32517", "Link": "https://www.scopus.com/inward/record.uri?eid=2-s2.0-85137619371&doi=10.3991%2fijoe.v18i11.32517&partnerID=40&md5=3e57fc13743d8a0250bc3c7d7a54ecfc" },
    { "Authors": "Raouhi E.M., Lachgar M., Hrimech H., Kartit A.", "Title": "Impact of regularization on the effectiveness of transfer learning for olive disease classification", "Year": "2022", "Source title": "11th International Symposium on Signal, Image, Video and Communications, ISIVC 2022 - Conference Proceedings", "DOI": "10.1109/ISIVC54825.2022.9800749", "Link": "https://www.scopus.com/inward/record.uri?eid=2-s2.0-85134035675&doi=10.1109%2fISIVC54825.2022.9800749&partnerID=40&md5=5695fea3f6e15ad172f3e20aee0b3f94" },
    { "Authors": "Aboulmira A., Raouhi E.M., Hrimech H., Lachgar M.", "Title": "Ensemble learning methods for deep learning: Application to skin lesions classification", "Year": "2022", "Source title": "11th International Symposium on Signal, Image, Video and Communications, ISIVC 2022 - Conference Proceedings", "DOI": "10.1109/ISIVC54825.2022.9800732", "Link": "https://www.scopus.com/inward/record.uri?eid=2-s2.0-85134015663&doi=10.1109%2fISIVC54825.2022.9800732&partnerID=40&md5=f3632a029e2e8802d1a1270dea3b4c64" },
    { "Authors": "Ezzahra H.F., Aatila M., Lachgar M., Abdali A.", "Title": "Predicting smart grid stability using machine learning algorithms", "Year": "2022", "Source title": "11th International Symposium on Signal, Image, Video and Communications, ISIVC 2022 - Conference Proceedings", "DOI": "10.1109/ISIVC54825.2022.9800733", "Link": "https://www.scopus.com/inward/record.uri?eid=2-s2.0-85134008493&doi=10.1109%2fISIVC54825.2022.9800733&partnerID=40&md5=3a06289d4d232cf246fe9163b805c55b" },
    { "Authors": "Raouhi E.M., Lachgar M., Hrimech H., Kartit A.", "Title": "Optimization techniques in deep convolutional neuronal networks applied to olive diseases classification", "Year": "2022", "Source title": "Artificial Intelligence in Agriculture", "DOI": "10.1016/j.aiia.2022.06.001", "Link": "https://www.scopus.com/inward/record.uri?eid=2-s2.0-85132529572&doi=10.1016%2fj.aiia.2022.06.001&partnerID=40&md5=aec5f40389d1d815c091bec6549e8044" },
    { "Authors": "Raouhi E.M., Lachgar M., Kartit A.", "Title": "Comparative Study of Regression and Regularization Methods: Application to Weather and Climate Data", "Year": "2022", "Source title": "Lecture Notes in Electrical Engineering", "DOI": "10.1007/978-981-33-6893-4_22", "Link": "https://www.scopus.com/inward/record.uri?eid=2-s2.0-85113335433&doi=10.1007%2f978-981-33-6893-4_22&partnerID=40&md5=6273a9a457213b0999886d4db56a6ef0" }
    ,
    { "Authors": "Mustapha A., Mohamed L., Ali K.", "Title": "Keratoconus Classification Using Machine Learning", "Year": "2022", "Source title": "Lecture Notes in Electrical Engineering", "DOI": "10.1007/978-981-33-6893-4_25", "Link": "https://www.scopus.com/inward/record.uri?eid=2-s2.0-85113327757&doi=10.1007%2f978-981-33-6893-4_25&partnerID=40&md5=634e10794c4a3f840b726b9116740ead" },
    { "Authors": "Hanine M., Boutkhoum O., Barakaz F.E., Lachgar M., Assad N., Rustam F., Ashraf I.", "Title": "An intuitionistic fuzzy approach for smart city development evaluation for developing countries: Moroccan context", "Year": "2021", "Source title": "Mathematics", "DOI": "10.3390/math9212668", "Link": "https://www.scopus.com/inward/record.uri?eid=2-s2.0-85117791321&doi=10.3390%2fmath9212668&partnerID=40&md5=c39658e290ba8ca80d4725f98ed6d60e" },
    { "Authors": "Mustapha A., Mohamed L., Ali K.", "Title": "Comparative study of optimization techniques in deep learning: Application in the ophthalmology field", "Year": "2021", "Source title": "Journal of Physics: Conference Series", "DOI": "10.1088/1742-6596/1743/1/012002", "Link": "https://www.scopus.com/inward/record.uri?eid=2-s2.0-85101731984&doi=10.1088%2f1742-6596%2f1743%2f1%2f012002&partnerID=40&md5=eda3ba6f1c26814da0eed7c1d7cc99cc" },
    { "Authors": "Aatila M., Lachgar M., Hamid H., Kartit A.", "Title": "Keratoconus Severity Classification Using Features Selection and Machine Learning Algorithms", "Year": "2021", "Source title": "Computational and Mathematical Methods in Medicine", "DOI": "10.1155/2021/9979560", "Link": "https://www.scopus.com/inward/record.uri?eid=2-s2.0-85120803849&doi=10.1155%2f2021%2f9979560&partnerID=40&md5=fd5b2ae82818fbd5e76fcc43909fec5b" },
    { "Authors": "Hanine M., Lachgar M., Elmahfoudi S., Boutkhoum O.", "Title": "MDA Approach for Designing and Developing Data Warehouses: A Systematic Review & Proposal", "Year": "2021", "Source title": "International journal of online and biomedical engineering", "DOI": "10.3991/ijoe.v17i10.24667", "Link": "https://www.scopus.com/inward/record.uri?eid=2-s2.0-85119437398&doi=10.3991%2fijoe.v17i10.24667&partnerID=40&md5=8e0e29b974084fe82836cad943964675" },
    { "Authors": "El Firdoussi S., Lachgar M., Kabaili H., Rochdi A., Goujdami D., El Firdoussi L.", "Title": "Assessing Distance Learning in Higher Education during the COVID-19 Pandemic", "Year": "2020", "Source title": "Education Research International", "DOI": "10.1155/2020/8890633", "Link": "https://www.scopus.com/inward/record.uri?eid=2-s2.0-85099286462&doi=10.1155%2f2020%2f8890633&partnerID=40&md5=9c6bdcde6d1771db575af04d343b4bca" },
    { "Authors": "Mustapha A., Mohamed L., Ali K.", "Title": "An Overview of Gradient Descent Algorithm Optimization in Machine Learning: Application in the Ophthalmology Field", "Year": "2020", "Source title": "Communications in Computer and Information Science", "DOI": "10.1007/978-3-030-45183-7_27", "Link": "https://www.scopus.com/inward/record.uri?eid=2-s2.0-85086237172&doi=10.1007%2f978-3-030-45183-7_27&partnerID=40&md5=5cb77386913283fe580c5213303f0d55" },
    { "Authors": "Lachgar M., Benouda H., Elfirdoussi S.", "Title": "Android REST APIs: Volley vs Retrofit", "Year": "2019", "Source title": "International Symposium on Advanced Electrical and Communication Technologies, ISAECT 2018 - Proceedings", "DOI": "10.1109/ISAECT.2018.8618824", "Link": "https://www.scopus.com/inward/record.uri?eid=2-s2.0-85062452679&doi=10.1109%2fISAECT.2018.8618824&partnerID=40&md5=5635659b283f3fc76b231845a2b4c034" },
    { "Authors": "Lamhaddab K., Lachgar M., Elbaamrani K.", "Title": "Porting mobile apps from iOS to android: A practical experience", "Year": "2019", "Source title": "Mobile Information Systems", "DOI": "10.1155/2019/4324871", "Link": "https://www.scopus.com/inward/record.uri?eid=2-s2.0-85072526740&doi=10.1155%2f2019%2f4324871&partnerID=40&md5=3af909cae967c01267f8b7598d0b1fe5" },
    { "Authors": "Lachgar M., Lamhaddab K., Abdali A., Elbaamrani K.", "Title": "Pragmatic approach to modeling and generating mobile cross-platform applications", "Year": "2019", "Source title": "Journal of Computer Science", "DOI": "10.3844/jcssp.2019.416.434", "Link": "https://www.scopus.com/inward/record.uri?eid=2-s2.0-85066838075&doi=10.3844%2fjcssp.2019.416.434&partnerID=40&md5=6faeb39c195e077b0b981d6f6086ea73" },
    { "Authors": "Lachgar M., Abdali A.", "Title": "Modeling and generating native code for cross-platform mobile applications using DSL", "Year": "2017", "Source title": "Intelligent Automation and Soft Computing", "DOI": "10.1080/10798587.2016.1239392", "Link": "https://www.scopus.com/inward/record.uri?eid=2-s2.0-84992061428&doi=10.1080%2f10798587.2016.1239392&partnerID=40&md5=e3d8120a934dda28760321fd3d219e84" },
    { "Authors": "Lachgar M., Abdali A.", "Title": "DSL and code generator for accelerating iOs apps development", "Year": "2016", "Source title": "Proceedings of 2015 IEEE World Conference on Complex Systems, WCCS 2015", "DOI": "10.1109/ICoCS.2015.7483269", "Link": "https://www.scopus.com/inward/record.uri?eid=2-s2.0-84978370290&doi=10.1109%2fICoCS.2015.7483269&partnerID=40&md5=add0668d751a026dd9637d13001d2733" },
    { "Authors": "Lachgar M., Abdali A.", "Title": "Generating Android graphical user interfaces using an MDA approach", "Year": "2015", "Source title": "Colloquium in Information Science and Technology, CIST", "DOI": "10.1109/CIST.2014.7016598", "Link": "https://www.scopus.com/inward/record.uri?eid=2-s2.0-84938079317&doi=10.1109%2fCIST.2014.7016598&partnerID=40&md5=584ea0203f2ea70fd4035762e18e3253" },
    { "Authors": "Lachgar M., Abdali A.", "Title": "Modeling and generating the user interface of mobile devices and web development with DSL", "Year": "2015", "Source title": "Journal of Theoretical and Applied Information Technology", "DOI": "", "Link": "https://www.scopus.com/inward/record.uri?eid=2-s2.0-84922704282&partnerID=40&md5=46e2eb6ff477d857ccbd4885aabd6c73" }
];

function Publications() {
    return (
        <section className="section">
            <PaperCard data={papers} />
        </section>
    );
}

export default Publications;
